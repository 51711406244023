<template>
  <div class="wrapper">

    <Header />

    <div class="pagenotfound">

      <div class="pagenotfound-content">

        <img src="@/assets/desert.svg">
        <h1 class="heading-r">Siden blev ikke fundet</h1>
        <p>Beklager, men den side du leder efter findes ikke!</p>

      </div>

    </div>

  </div>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'pagenotfound',
  components: {
    Header
  }
}
</script>

<style lang="scss" scoped>
.pagenotfound {
  width: 100%;
  height: calc(100vh - 86px);
  display: flex;
  justify-content: center;
  align-items: center;
  &-content {
    width: 100%;
    text-align: center;
    padding: 0 24px;
    img {
      width: 300px;
      height: 200px;
      margin: 0 auto 32px auto;
    }
    h1 {
      margin-bottom: 16px;
    }
  }
}
</style>
